import {
  create,
  NGi,
  NGrid,
  NInput,
  NConfigProvider,
  NDivider,
  NFormItemGi,
  NForm,
  NSwitch,
  NButton,
  NSpace,
  NAlert,
  NIcon,
  NInputGroup,
  NText,
  NModal,
  NCard,
  NImage,
} from "naive-ui";

export const naive = create({
  components: [
    NGi,
    NGrid,
    NInput,
    NConfigProvider,
    NDivider,
    NFormItemGi,
    NForm,
    NSwitch,
    NButton,
    NSpace,
    NAlert,
    NIcon,
    NText,
    NInputGroup,
    NModal,
    NCard,
    NImage,
  ],
});
